"use strict";
import $ from "jquery";
import Swiper from "swiper/dist/js/swiper.min.js";

class Top {
  init() {
    window.addEventListener(
      "load",
      () => {
        this.mvSlide();
        if (document.getElementsByClassName("J-top-news-list").length) {
          this.newsInit();
        }
      },
      false
    );
    this.pickUpSlide();
  }

  newsInit() {
    fetch("/json/news.json")
      .then((res) => res.json())
      .then((data) => {
        const category_list = data.category;
        let post_list = data.post.map(
          // カテゴリースラッグから、カテゴリー名を検索し、値として追加する
          (this_data) => {
            let this_post_category = category_list.find(
              (category) => category.slug == this_data.category_slug
            );
            this_data.category_name = this_post_category.name;
            return this_data;
          }
        );

        // 最大5件
        post_list = post_list.slice(0, 5);

        let html = "";
        post_list.forEach(function (data) {
          html += `
        <a class="news__item" href="/news/${data.slug}.html">
        <div class="news__text">
          <div class="news__date">${data.date}</div>
          <div class="news__title">${data.title}</div>
        </div></a>
        `;
        });
        $(".J-top-news-list").html(html);
      });
  }

  mvSlide() {
    const mvSwiper = new Swiper(".mv .swiper-container", {
      speed: 800,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      loopAdditionalSlides: 1,
      slidesPerView: 1.3,
      spaceBetween: 8,
      centeredSlides: true,
      slideToClickedSlide: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        767: {
          slidesPerView: 1,
          spaceBetween: 0,
        },
      },
    });
  }

  pickUpSlide() {
    let pickUpSwiper, pickUpSwiperBool;

    window.addEventListener(
      "load",
      () => {
        /* if (window.matchMedia('(max-width: 768px)').matches) {
          createPickUpSwiper();
          pickUpSwiperBool = true;
        } else {
          pickUpSwiperBool = false;
        } */

        // スライドが増えてきたため、PCもスワイパー仕様に
        createPickUpSwiper();
        pickUpSwiperBool = true;
      },
      false
    );

    window.addEventListener(
      "resize",
      () => {
        /* if (
          window.matchMedia('(max-width: 768px)').matches &&
          !pickUpSwiperBool
        ) {
          createPickUpSwiper();
          pickUpSwiperBool = true;
        } else if (
          window.matchMedia('(min-width: 769px)').matches &&
          pickUpSwiperBool
        ) {
          pickUpSwiper.destroy();
          pickUpSwiperBool = false;
        } */
        pickUpSwiper.destroy();
        createPickUpSwiper();
      },
      false
    );

    const createPickUpSwiper = () => {
      pickUpSwiper = new Swiper(".pickup .swiper-container", {
        speed: 600,
        // loop: true,
        slidesPerView: 2,
        spaceBetween: 20,
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
        navigation: {
          nextEl: ".J-swiper-next-pc",
          prevEl: ".J-swiper-prev-pc",
        },
        breakpoints: {
          768: {
            slidesPerView: "auto",
            loop: true,
            navigation: {
              nextEl: ".J-swiper-next-sp",
              prevEl: ".J-swiper-prev-sp",
            },
          },
        },
      });
    };
  }
}
const top = new Top();
top.init();
